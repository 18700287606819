import React from "react";
import { INameDrawParticipant } from "./interfaces";
import NameDrawExclusionSelect, {
  INameDrawExclusionContext,
} from "./NameDrawExclusionSelect";
import Box from "@mui/material/Box";
import { Alert, List, ListItem } from "@mui/material";

export interface INameDrawExclusionSelectionProps {
  participants: INameDrawParticipant[];
  exclusionListMap: IExclusionListMap | undefined;
  setExclusionValidationState: (
    state: boolean,
    errorMessage: string | undefined,
  ) => void;
}

export interface IExclusionListMap {
  [id: string]: INameDrawExclusionContext;
}

export default function ManageNameDrawExclusions({
  participants,
  exclusionListMap,
  setExclusionValidationState,
}: INameDrawExclusionSelectionProps) {
  const maxExclusionCount = participants.length - 2;
  const onExclusionSelected = (): boolean => {
    if (exclusionListMap === undefined) return false;

    let count = 0;
    Object.keys(exclusionListMap as object).forEach((key) => {
      const map = exclusionListMap[key];
      count += map.selectedExclusionsCount;
    });

    if (count > maxExclusionCount) {
      setExclusionValidationState(
        true,
        `Maximum exclusions allowed are ${maxExclusionCount}. Currently ${count} exclusion(s) are selected. Please decrease number of exclusions.`,
      );
      return false;
    }
    setExclusionValidationState(false, undefined);
    return true;
  };

  return (
    <>
      {exclusionListMap && participants && (
        <>
          <Alert severity={"info"}>
            For each member, select any members that they should not be matched
            with. You can have a maximum of {maxExclusionCount} exclusion(s).
          </Alert>
          <Box>
            <List>
              {participants.map((participant) => {
                return (
                  <ListItem key={participant.member.id}>
                    <NameDrawExclusionSelect
                      key={participant.member.id}
                      exclusionContext={exclusionListMap[participant.member.id]}
                      onExclusionSelected={onExclusionSelected}
                    />
                  </ListItem>
                );
              })}
            </List>
          </Box>
        </>
      )}
    </>
  );
}
