import { IMember } from "./interfaces";
import { CircularProgress } from "@mui/material";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleExclamation,
  faSquareCheck,
} from "@fortawesome/free-solid-svg-icons";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useSendNameDrawEmailFamilyFamilyIdMemberMemberIdSenddrawPutMutation } from "../../store/apiSlice";

export interface INameDrawSendEmailProps {
  familyId: string;
  member: IMember;
}
export default function NameDrawSendEmail({
  familyId,
  member,
}: INameDrawSendEmailProps) {
  const [
    sendEmailTrigger,
    { isLoading: isSendingEmail, isError, isSuccess, isUninitialized },
  ] = useSendNameDrawEmailFamilyFamilyIdMemberMemberIdSenddrawPutMutation();

  const onSendEmail = () => {
    sendEmailTrigger({ familyId: familyId, memberId: member.id });
  };

  const buttonText = isError ? "Try Again" : "Send Email";

  return (
    <Box
      key={member.id}
      sx={{
        display: "flex",
        borderRadius: 1,
        borderColor: "primary.main",
      }}
    >
      <>
        <Typography sx={{ mr: "1rem" }}>{member.name}</Typography>
        {isSuccess && (
          <FontAwesomeIcon
            style={{ marginRight: "1rem" }}
            color={"green"}
            icon={faSquareCheck}
          />
        )}

        {isError && (
          <FontAwesomeIcon
            style={{ marginRight: ".5rem" }}
            color={"red"}
            icon={faCircleExclamation}
          />
        )}

        {(isUninitialized || isSendingEmail || isError) && (
          <Button
            size={"small"}
            disabled={isSendingEmail}
            sx={{ mr: "1rem" }}
            variant="outlined"
            color="primary"
            type="button"
            onClick={() => onSendEmail()}
          >
            {buttonText}
            {isSendingEmail && <CircularProgress size={"1rem"} />}
          </Button>
        )}
      </>
    </Box>
  );
}
