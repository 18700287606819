import { INameDrawParticipant } from "./interfaces";
import {
  Chip,
  FormControl,
  InputLabel,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  useTheme,
} from "@mui/material";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import React, { useState } from "react";
import { Theme } from "@mui/material/styles";

function getStyles(
  id: string,
  participant: INameDrawParticipant,
  theme: Theme,
) {
  return {
    fontWeight:
      participant.member.id === id
        ? theme.typography.fontWeightMedium
        : theme.typography.fontWeightRegular,
  };
}

export interface INameDrawExclusionContext {
  participant: INameDrawParticipant;
  selectedExclusionIds: string[];
  exclusionList: INameDrawParticipant[];
  selectedExclusionsCount: number;
}

export interface INameDrawExclusionSelectProps {
  exclusionContext: INameDrawExclusionContext;
  onExclusionSelected: (contextMember: INameDrawExclusionContext) => boolean;
}

export default function NameDrawExclusionSelect({
  exclusionContext,
  onExclusionSelected,
}: INameDrawExclusionSelectProps) {
  const theme = useTheme();
  const [exclusionSelection, setExclusionSelection] = useState<string[]>([]);

  const handleChange = (event: SelectChangeEvent<string[] | string>) => {
    const {
      target: { value },
    } = event;

    const newValue = typeof value === "string" ? value.split(",") : value;

    exclusionContext.selectedExclusionIds = newValue;
    exclusionContext.selectedExclusionsCount = newValue.length;

    setExclusionSelection(newValue);
    onExclusionSelected(exclusionContext);
  };
  return (
    <Box>
      <FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel
          id={`member-name-label=${exclusionContext.participant.member.id}`}
        >
          {`${exclusionContext.participant.member.name} exclusions`}
        </InputLabel>
        <Select
          labelId={`member-name-label-${exclusionContext.participant.member.id}`}
          id={`member-exclusion-select-${exclusionContext.participant.member.id}`}
          multiple
          value={exclusionSelection}
          onChange={(e) => handleChange(e)}
          input={
            <OutlinedInput
              id={`member-exclusion-select-multiple-chip-${exclusionContext.participant.member.id}`}
              label="Chip"
            />
          }
          renderValue={(selected: string[]) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.map((value: string) => (
                <Chip
                  key={value}
                  label={
                    exclusionContext.exclusionList.filter(
                      (s) => s.member.id === value,
                    )[0].member.name
                  }
                />
              ))}
            </Box>
          )}
        >
          {exclusionContext.exclusionList.map((exclusionListMember) => (
            <MenuItem
              key={`exclusion-menu-item-${exclusionListMember.member.id}`}
              value={exclusionListMember.member.id}
              style={getStyles(
                exclusionContext.participant.member.id,
                exclusionListMember,
                theme,
              )}
            >
              {exclusionListMember.member.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
