import { IMemberWish, IMemberWishAttribute } from "./interfaces";
import { Wish } from "../../store/apiSlice";

export function mapAttributesToArray(
  data: IMemberWish,
): IMemberWishAttribute[] {
  const result: IMemberWishAttribute[] = [];
  if (data.attributes) {
    let counter = 0;
    Object.keys(data.attributes).forEach((id) => {
      result.push({
        key: counter.toString(),
        id,
        value:
          data.attributes && id in data.attributes
            ? data.attributes[id]
            : undefined,
      } as IMemberWishAttribute);
      counter++;
    });
  }

  return result;
}

export function mapApiWishToMemberWish(apiData: Wish | undefined): IMemberWish {
  if (!apiData) {
    return {} as IMemberWish;
  }
  return {
    id: apiData.wish_id,
    title: apiData.title,
    exampleUrl: apiData.example_url,
    attributes: apiData.properties,
    ownerId: apiData.owner_id,
  } as IMemberWish;
}
