import Stack from "@mui/material/Stack";
import NameDrawSendEmail from "./NameDrawSendEmail";
import React from "react";
import { IMember } from "./interfaces";

export interface IManageNameDrawSendEmail {
  familyId: string;
  selectedMembers: IMember[];
}
export default function ManageNameDrawSendEmail({
  familyId,
  selectedMembers,
}: IManageNameDrawSendEmail) {
  return (
    <>
      <Stack sx={{ mb: "1rem" }} spacing={2}>
        {selectedMembers.map((member) => (
          <NameDrawSendEmail
            key={member.id}
            familyId={familyId}
            member={member}
          />
        ))}
      </Stack>
    </>
  );
}
