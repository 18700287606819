import React, { useEffect, useState } from "react";
import {
  CreateNameDrawRequest,
  FamilyMember,
  useDrawNamesFamilyFamilyIdDrawPutMutation,
  useMyFamilyMembersFamilyMeMembersGetQuery,
} from "../../store/apiSlice";
import { Alert, CircularProgress, List, ListItemButton } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { NotFoundError } from "../../models/errorModels";
import { INameDrawParticipant } from "./interfaces";
import { mapApiMember } from "./functions";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareCheck } from "@fortawesome/free-solid-svg-icons";
import Button from "@mui/material/Button";
import ManageNameDrawExclusions, {
  IExclusionListMap,
} from "./ManageNameDrawExclusions";
import ManageNameDrawSendEmail from "./ManageNameDrawSendEmail";
import { INameDrawExclusionContext } from "./NameDrawExclusionSelect";
import Divider from "@mui/material/Divider";

enum StageEnum {
  Selection,
  Exclusion,
  Submission,
  SendEmail,
  Canceled,
  Complete,
}

export interface ICreateNameDrawProps {
  familyId?: string;
}
export default function CreateNameDraw({ familyId }: ICreateNameDrawProps) {
  const routeParams = useParams();

  const famId = familyId ? familyId : routeParams.familyId ?? "";

  if (!famId) throw new NotFoundError();

  const {
    data: myFamilyData,
    isLoading: isLoadingMyFamily,
    error: myFamilyError,
  } = useMyFamilyMembersFamilyMeMembersGetQuery();

  const [
    drawTrigger,
    {
      isLoading: isSubmitting,
      error: createDrawError,
      isSuccess: isCreateDrawSuccess,
    },
  ] = useDrawNamesFamilyFamilyIdDrawPutMutation();

  const [actionButtonText, setActionButtonText] = useState("Next");

  const [exclusionListMap, setExclusionListMap] = useState<IExclusionListMap>();

  const [participants, setParticipants] = useState<INameDrawParticipant[]>([]);
  const [selectedMembers, setSelectedMembers] = useState<FamilyMember[]>([]);
  const [workflowStage, setWorkflowStage] = useState<StageEnum>(
    StageEnum.Selection,
  );
  const [validationError, setValidationError] = useState<string | undefined>();

  const navigate = useNavigate();

  useEffect(() => {
    if (isCreateDrawSuccess) {
      setActionButtonText("Done");
      setWorkflowStage(StageEnum.SendEmail);
    }
  }, [isCreateDrawSuccess]);

  useEffect(() => {
    if (workflowStage === StageEnum.Canceled) {
      navigate(`/family/${famId}/managedraw`);
    }

    if (workflowStage === StageEnum.Complete) {
      navigate("/myfamily");
    }

    if (workflowStage === StageEnum.Submission) {
      onSubmit();
    }
  }, [workflowStage]);

  useEffect(() => {
    if (myFamilyError) {
      setValidationError(
        "Unable to load name draw info. Please refresh your browser.",
      );
    }

    if (createDrawError) {
      setValidationError("Unable to create a name draw. Please try again.");
    }
  }, [myFamilyError, createDrawError]);

  const isSelected = (memberId: string | null | undefined) => {
    if (!memberId) return false;
    return selectedMembers.some((m) => m.member_id === memberId);
  };

  const handleSelectedMember = (member: FamilyMember | null | undefined) => {
    if (!member) return;

    setValidationError(undefined);

    if (isSelected(member.member_id)) {
      setSelectedMembers(
        selectedMembers.filter((m) => m.member_id !== member.member_id),
      );
    } else {
      setSelectedMembers([...selectedMembers, member]);
    }
  };

  const onCancelClicked = () => {
    setWorkflowStage(StageEnum.Canceled);
  };

  const onSubmit = () => {
    drawTrigger({
      familyId: famId,
      createNameDrawRequest: {
        participating_members: participants.map((m) => ({
          member_id: m.member.id,
          excluded_member_ids:
            exclusionListMap !== undefined
              ? exclusionListMap[m.member.id].selectedExclusionIds
              : undefined,
        })),
      } as CreateNameDrawRequest,
    });
  };

  const mapMember = (
    m: FamilyMember,
    familyId: string,
  ): INameDrawParticipant => {
    return {
      familyId: familyId,
      member: mapApiMember(m),
    } as INameDrawParticipant;
  };

  const onActionClicked = () => {
    if (workflowStage === StageEnum.Selection) {
      if (validationError !== undefined) return;

      const mappedParticipants = selectedMembers.map((m) =>
        mapMember(m, famId),
      );

      if (mappedParticipants.length < 2) {
        setValidationError("Must select at least 2 participants.");
        return;
      }

      setParticipants(mappedParticipants);
      setActionButtonText("Submit");
      if (mappedParticipants.length > 2) {
        const res: IExclusionListMap = {};
        for (let i = 0; i < mappedParticipants.length; i++) {
          const p = mappedParticipants[i];
          const compareFn = (
            a: INameDrawParticipant,
            b: INameDrawParticipant,
          ) => {
            if (a.member.name < b.member.name) {
              return -1;
            } else if (a.member.name > b.member.name) {
              return 1;
            }
            // a must be equal to b
            return 0;
          };

          const exclusionList = mappedParticipants
            .filter((m) => m.member.id !== p.member.id)
            .sort(compareFn);
          res[p.member.id] = {
            participant: p,
            exclusionList: exclusionList,
            selectedExclusionIds: [] as string[],
            selectedExclusionsCount: 0,
          } as INameDrawExclusionContext;
        }

        setExclusionListMap(res);

        setWorkflowStage(StageEnum.Exclusion);
      } else {
        setWorkflowStage(StageEnum.Submission);
      }

      return;
    }

    if (workflowStage === StageEnum.Exclusion) {
      setWorkflowStage(StageEnum.Submission);
      return;
    }

    if (workflowStage === StageEnum.SendEmail) {
      setWorkflowStage(StageEnum.Complete);
      return;
    }
  };
  return (
    <>
      {isLoadingMyFamily && <CircularProgress />}
      {validationError && <Alert severity={"error"}>{validationError}</Alert>}
      {!isLoadingMyFamily &&
        myFamilyData?.members &&
        workflowStage === StageEnum.Selection && (
          <>
            <Box>
              <Typography>Select all members that will participate:</Typography>
            </Box>
            <Box>
              <List aria-label="name draw members">
                {myFamilyData.members.map((m) => (
                  <ListItemButton
                    key={m.member_id}
                    selected={isSelected(m.member_id)}
                    onClick={() => handleSelectedMember(m)}
                  >
                    {isSelected(m.member_id) && (
                      <FontAwesomeIcon
                        style={{ marginRight: ".5rem" }}
                        icon={faSquareCheck}
                      />
                    )}
                    {m.member_name}
                  </ListItemButton>
                ))}
              </List>
            </Box>
          </>
        )}

      {workflowStage === StageEnum.Exclusion && (
        <>
          <ManageNameDrawExclusions
            participants={participants}
            exclusionListMap={exclusionListMap}
            setExclusionValidationState={(_, msg) => {
              setValidationError(msg);
            }}
          />
        </>
      )}
      {workflowStage === StageEnum.SendEmail && (
        <>
          <Alert sx={{ mb: "1rem" }} severity={"info"}>
            The draw is complete! Send an email to let each member know who they
            have drawn.
          </Alert>

          <ManageNameDrawSendEmail
            familyId={famId}
            selectedMembers={participants.map((m) => m.member)}
          />
        </>
      )}
      <Box>
        <Divider variant={"fullWidth"} sx={{ mt: "1rem", mb: "1rem" }} />
        <Button
          sx={{ mr: "1rem" }}
          disabled={isSubmitting || validationError !== undefined}
          variant="outlined"
          color="primary"
          type="button"
          onClick={() => onActionClicked()}
        >
          {actionButtonText}{" "}
          {isSubmitting && <CircularProgress size={"1rem"} />}
        </Button>

        {workflowStage !== StageEnum.SendEmail &&
          workflowStage !== StageEnum.Complete && (
            <Button
              variant="outlined"
              color="secondary"
              type="button"
              onClick={() => onCancelClicked()}
            >
              Cancel
            </Button>
          )}
      </Box>
    </>
  );
}
