import { Alert, Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useGetFamilyNameDrawFamilyFamilyIdDrawGetQuery } from "../../store/apiSlice";
import { IMember } from "./interfaces";
import Stack from "@mui/material/Stack";
import ManageNameDrawSendEmail from "./ManageNameDrawSendEmail";
import { mapApiMember } from "./functions";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Divider from "@mui/material/Divider";

function ManageNameDrawPlaceholder() {
  const placeholderStyles = {
    width: "100%",
    marginBottom: "1rem",
  };
  return (
    <Stack sx={placeholderStyles} spacing={1}>
      <Skeleton variant="text" height={"8rem"} />
      <Skeleton variant="rounded" height={"3rem"} />
    </Stack>
  );
}

export interface IManageNameDrawProps {
  familyId?: string;
}
export default function ManageNameDraw({ familyId }: IManageNameDrawProps) {
  const params = useParams();

  familyId = familyId ?? params.familyId;

  if (!familyId) {
    throw new Error("Missing familyId props and no route state was given.");
  }

  const {
    data: nameDrawData,
    isLoading: isLoadingData,
    error: nameDrawError,
  } = useGetFamilyNameDrawFamilyFamilyIdDrawGetQuery({ familyId });

  const [selectedMembers, setSelectedMembers] = useState<IMember[]>([]);

  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoadingData && nameDrawData && nameDrawData.participating_members) {
      const mappedMembers =
        nameDrawData.participating_members.map(mapApiMember);
      setSelectedMembers(mappedMembers);
    }
  }, [nameDrawData, isLoadingData]);
  return (
    <>
      {nameDrawError && (
        <Alert severity={"error"}>
          Unable to load name draw info. Please refresh your browser.
        </Alert>
      )}
      {isLoadingData && <ManageNameDrawPlaceholder />}
      {!isLoadingData && (
        <>
          <Box>
            <Box>
              <Button
                sx={{ mr: "1rem" }}
                variant="outlined"
                color="primary"
                type="button"
                onClick={() => navigate(`/family/${familyId}/createdraw`)}
              >
                Create Name Draw
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                type="button"
                onClick={() => navigate(`/myfamily`)}
              >
                Cancel
              </Button>
            </Box>

            {selectedMembers && selectedMembers.length > 0 && (
              <>
                <Divider
                  sx={{ mt: "1rem", mb: "1rem" }}
                  variant={"fullWidth"}
                />
                <Box>
                  <Alert sx={{ mb: "1rem" }} severity={"info"}>
                    There is an active name draw. Below, you can send/re-send
                    the notification email to each participant that informs them
                    of their match.
                  </Alert>
                </Box>
                <Box>
                  <ManageNameDrawSendEmail
                    familyId={familyId}
                    selectedMembers={selectedMembers}
                  />
                </Box>
              </>
            )}
          </Box>
        </>
      )}
    </>
  );
}
