import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./home";
import MemberView from "./member/MemberView";
import * as React from "react";
import Layout from "../components/Layout";
import ErrorPage from "./error";
import { ProtectedRoute } from "../components/ProtectedRoute";
import LogIn from "./login";
import AddMemberWish from "./member/AddMemberWish";
import AcceptInvite from "./family/AcceptInvite";
import Me from "./member/Me";
import MyFamily from "./family/MyFamily";
import EditMemberWish from "./member/EditMemberWish";
import CreateInvite from "./family/CreateInvite";
import CreateNameDraw from "./family/CreateNameDraw";
import ManageNameDraw from "./family/ManageNameDraw";

const router = createBrowserRouter([
  {
    element: <Layout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <Home />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/me",
        element: (
          <ProtectedRoute>
            <Me />
          </ProtectedRoute>
        ),
        errorElement: <ErrorPage />,
      },
      {
        path: "/acceptInvite",
        element: (
          <ProtectedRoute>
            <AcceptInvite />
          </ProtectedRoute>
        ),
        errorElement: <ErrorPage />,
      },
      {
        path: "createInvite",
        element: (
          <ProtectedRoute>
            <CreateInvite />
          </ProtectedRoute>
        ),
        errorElement: <ErrorPage />,
      },
      {
        path: "/family/:familyId/createdraw",
        element: (
          <ProtectedRoute>
            <CreateNameDraw />
          </ProtectedRoute>
        ),
        errorElement: <ErrorPage />,
      },
      {
        path: "/family/:familyId/managedraw",
        element: (
          <ProtectedRoute>
            <ManageNameDraw />
          </ProtectedRoute>
        ),
        errorElement: <ErrorPage />,
      },
      {
        path: "/myfamily",
        element: (
          <ProtectedRoute>
            <MyFamily />
          </ProtectedRoute>
        ),
        errorElement: <ErrorPage />,
      },
      {
        path: "/member/:memberId",
        element: (
          <ProtectedRoute>
            <MemberView />
          </ProtectedRoute>
        ),
        errorElement: <ErrorPage />,
      },
      {
        path: "/editwish/:wishId",
        element: (
          <ProtectedRoute>
            <EditMemberWish />
          </ProtectedRoute>
        ),
        errorElement: <ErrorPage />,
      },
      {
        path: "/addwish",
        element: (
          <ProtectedRoute>
            <AddMemberWish />
          </ProtectedRoute>
        ),
        errorElement: <ErrorPage />,
      },
      {
        path: "/error",
        element: <ErrorPage />,
      },
      {
        path: "/login",
        element: <LogIn />,
        errorElement: <ErrorPage />,
      },
    ],
  },
]);

export default <RouterProvider router={router} />;
