import React, { useEffect, useState } from "react";
import { MemberProfileCard } from "./MemberProfileCard";
import Box from "@mui/material/Box";
import { useNavigate, useParams } from "react-router-dom";
import { NotFoundError } from "../../models/errorModels";
import MemberWishList from "./MemberWishList";
import { IMemberWish, IMemberWishGranter } from "./interfaces";
import { useAuth } from "../../hooks/useAuth";
import { Skeleton } from "@mui/material";
import Divider from "@mui/material/Divider";
import {
  useGetMemberMemberMemberIdGetQuery,
  useListMemberWishesMemberMemberIdWishGetQuery,
} from "../../store/apiSlice";
import { mapApiMember } from "../family/functions";
import { IMember } from "../family/interfaces";
import Stack from "@mui/material/Stack";

function ProfileCardPlaceholder() {
  const placeholderStyles = {
    width: "100%",
    marginBottom: "1rem",
  };
  return (
    <Stack sx={placeholderStyles} spacing={1}>
      <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
      <Skeleton variant="circular" width={"4rem"} height={"4rem"} />
      <Skeleton variant="rounded" height={"3rem"} />
    </Stack>
  );
}

function WishListPlaceholder() {
  const placeholderStyles = {
    width: "100%",
    marginBottom: "1rem",
  };
  return (
    <Stack sx={placeholderStyles} spacing={1}>
      <Skeleton variant="rounded" height={"4rem"} />
      <Skeleton variant="rounded" height={"4rem"} />
      <Skeleton variant="rounded" height={"4rem"} />
    </Stack>
  );
}

export interface IListMemberWishesProps {
  memberId?: string;
}

export default function MemberView({ memberId }: IListMemberWishesProps) {
  const params = useParams();
  const { authContext } = useAuth();
  const navigate = useNavigate();
  const [member, setMember] = useState<IMember>();

  memberId = memberId ?? params.memberId;

  if (!memberId) {
    throw new NotFoundError();
  }

  const currentUserId = authContext.userId ?? "";

  const {
    data: memberData,
    isLoading: memberLoading,
    error: memberError,
  } = useGetMemberMemberMemberIdGetQuery(
    {
      memberId: memberId,
    },
    { pollingInterval: 5 * 60 * 1000 },
  );

  const {
    data: listWishData,
    isLoading,
    error,
  } = useListMemberWishesMemberMemberIdWishGetQuery(
    {
      memberId,
    },
    { pollingInterval: 60 * 1000 },
  );

  const [wishes, setWishes] = useState<IMemberWish[]>();

  useEffect(() => {
    if (memberData) {
      const familyMember = mapApiMember(memberData);
      setMember(familyMember);
    }
  }, [memberData]);

  useEffect(() => {
    if (listWishData && listWishData.wishes) {
      const sortedWishes = [...listWishData.wishes].sort((a) =>
        a.granter ? 1 : -1,
      );
      setWishes(
        sortedWishes.map(
          (wish) =>
            ({
              id: wish.wish_id,
              title: wish.title,
              exampleUrl: wish.example_url,
              ownerId: memberId,
              granter:
                wish.granter && wish.granter.length === 2
                  ? ({
                      granterUserId: wish.granter[0],
                      granterName: wish.granter[1],
                    } as IMemberWishGranter)
                  : undefined,
              attributes: wish.properties,
            }) as IMemberWish,
        ),
      );
    }
  }, [listWishData]);

  useEffect(() => {
    if (error || memberError) {
      navigate("/error", { state: error ?? memberError });
    }
  }, [error, memberError]);

  return (
    <>
      <Box>
        {memberLoading && <ProfileCardPlaceholder />}
        {!memberLoading && member && <MemberProfileCard member={member} />}
      </Box>
      <Divider variant={"middle"} sx={{ mt: "1rem", mb: "1rem" }} />
      <Box sx={{ mb: "1rem" }}>
        {isLoading && <WishListPlaceholder />}
        {!isLoading && (
          <MemberWishList wishes={wishes} currentUserId={currentUserId} />
        )}
      </Box>
    </>
  );
}
