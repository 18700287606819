import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";
import { Alert, CircularProgress } from "@mui/material";
import { mapAttributesToArray } from "./functions";
import { IMemberWish } from "./interfaces";
import FlareIcon from "@mui/icons-material/Flare";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import { MemberWishGrantManager } from "./MemberWishGrantManager";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import { useNavigate } from "react-router-dom";
import {
  useDeleteMemberWishMemberMemberIdWishWishIdDeleteMutation,
  useGrantMemberWishMemberMemberIdWishWishIdGrantPostMutation,
  useUngrantMemberWishMemberMemberIdWishWishIdGrantDeleteMutation,
} from "../../store/apiSlice";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import Stack from "@mui/material/Stack";

export interface IUserWishCardProps {
  data: IMemberWish;
  currentUserId: string;
}

export default function MemberWishCard({
  data,
  currentUserId,
}: IUserWishCardProps) {
  const [
    ungrantWishTrigger,
    { isLoading: isSubmittingUngrant, error: ungrantError },
  ] = useUngrantMemberWishMemberMemberIdWishWishIdGrantDeleteMutation();

  const [
    grantWishTrigger,
    { isLoading: isSubmittingGrant, error: grantError },
  ] = useGrantMemberWishMemberMemberIdWishWishIdGrantPostMutation();

  const [deleteWishTrigger, { isLoading: isDeleting, error: deleteError }] =
    useDeleteMemberWishMemberMemberIdWishWishIdDeleteMutation();

  const [editId, setEditId] = useState("");

  const attributes = mapAttributesToArray(data);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteWishId, setDeleteWishId] = useState<string>();

  const navigate = useNavigate();

  useEffect(() => {
    if (editId !== "") {
      navigate(`/editwish/${editId}`);
    }
  }, [editId]);

  const onEditClick = (wishId: string) => {
    setEditId(wishId);
  };

  const onDeleteClick = (wishId: string) => {
    setDeleteWishId(wishId);
    setDeleteDialogOpen(true);
  };

  const onDeleteConfirm = (memberId: string) => {
    if (deleteWishId) {
      deleteWishTrigger({ memberId, wishId: deleteWishId });
      setDeleteDialogOpen(false);
    }
  };

  const onDeleteConfirmClose = () => {
    setDeleteDialogOpen(false);
  };

  const onGrantWish = () => {
    grantWishTrigger({ wishId: data.id, memberId: data.ownerId ?? "" });
  };

  const onUngrantWish = () => {
    ungrantWishTrigger({ wishId: data.id, memberId: data.ownerId ?? "" });
  };
  return (
    <Card variant={"outlined"} sx={{ width: "100%" }}>
      <CardHeader
        titleTypographyProps={{ variant: "h5" }}
        avatar={
          <Avatar sx={{ bgcolor: "#77e6ce" }} aria-label="wish">
            <FlareIcon />
          </Avatar>
        }
        title={data.title}
      />
      <CardContent>
        {deleteError && (
          <Alert severity="error">
            There was an error deleting this wysh. Please try again.
          </Alert>
        )}

        {data.exampleUrl && (
          <>
            <Box sx={{ mb: "1rem" }}>
              <Link href={data.exampleUrl} target="_blank" color="primary">
                <Button variant="contained">See Example</Button>
              </Link>
            </Box>
          </>
        )}

        {attributes.length > 0 && (
          <>
            <Box>
              <Typography variant={"overline"}>Details</Typography>
              <Stack spacing={1} direction={"column"}>
                {attributes.map((a) => (
                  <Box key={a.key}>
                    <Typography sx={{ fontSize: "small" }}>
                      <strong>{a.id}</strong>: {a.value}
                    </Typography>
                  </Box>
                ))}
              </Stack>
            </Box>
          </>
        )}
      </CardContent>

      <CardActions>
        <MemberWishGrantManager
          wishOwnerId={data.ownerId}
          currentUserId={currentUserId}
          isSubmitting={isSubmittingGrant || isSubmittingUngrant}
          onGrantWish={onGrantWish}
          onUngrantWish={onUngrantWish}
          granter={data.granter}
          hadGrantError={!!grantError}
          hadUngrantError={!!ungrantError}
        />
        {currentUserId.toLowerCase() === data.ownerId?.toLowerCase() && (
          <>
            <ConfirmationDialog
              title={"Are you sure you wanna delete it?"}
              description={""}
              isOpen={deleteDialogOpen}
              onConfirm={() => onDeleteConfirm(currentUserId)}
              onClose={onDeleteConfirmClose}
              onDismiss={onDeleteConfirmClose}
            />
            <Button
              variant="outlined"
              sx={{ borderRadius: "1", mr: ".5rem", mt: ".5rem" }}
              onClick={() => onEditClick(data.id)}
            >
              <Typography>Edit</Typography>
            </Button>
            <Button
              variant="outlined"
              sx={{
                borderColor: "secondary.main",
                color: "secondary.main",
                borderRadius: "1",
                mt: ".5rem",
              }}
              onClick={() => onDeleteClick(data.id)}
            >
              <Typography sx={{ mr: ".5rem" }}>Delete</Typography>
              {isDeleting && <CircularProgress size={"1rem"} />}
            </Button>
          </>
        )}
      </CardActions>
    </Card>
  );
}
