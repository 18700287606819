import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Alert, Skeleton } from "@mui/material";
import Stack from "@mui/material/Stack";
import FamilyMember from "./FamilyMember";
import { mapApiMember } from "./functions";
import { useMyFamilyMembersFamilyMeMembersGetQuery } from "../../store/apiSlice";
import Box from "@mui/material/Box";
import { IMember } from "./interfaces";
import { useAuth } from "../../hooks/useAuth";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

function FamilyMemberListPlaceholder() {
  const placeholderStyles = {
    width: "100%",
    marginBottom: "1rem",
  };
  return (
    <Stack sx={placeholderStyles} spacing={1}>
      <Skeleton variant="text" height={"8rem"} />
      <Skeleton variant="rounded" height={"3rem"} />
      <Skeleton variant="rounded" height={"3rem"} />
      <Skeleton variant="rounded" height={"3rem"} />
      <Skeleton variant="rounded" height={"3rem"} />
      <Skeleton variant="rounded" height={"3rem"} />
      <Skeleton variant="rounded" height={"3rem"} />
    </Stack>
  );
}

export default function MyFamily() {
  const { data, isLoading, error } =
    useMyFamilyMembersFamilyMeMembersGetQuery();

  const { authContext } = useAuth();

  const [members, setMembers] = useState<IMember[]>();
  const navigate = useNavigate();

  const hasMembers = members && members.length > 0;

  useEffect(() => {
    if (error) {
      navigate("/error", { state: error });
    }
  }, [error]);

  useEffect(() => {
    if (data) {
      const mappedMembers = data.members?.map((m) => mapApiMember(m));

      const filteredMembers = mappedMembers?.filter(
        (m) => m.id !== authContext.userId,
      );

      setMembers(filteredMembers);
    }
  }, [data]);

  const handleCreateInvite = () => {
    if (data) {
      navigate("/createInvite", {
        state: { familyId: data.family_id },
      });
    }
  };

  return (
    <>
      {data?.family_name && (
        <>
          <Box textAlign={"center"}>
            <Typography
              sx={{
                typography: { xs: "h3", sm: "h3", md: "h4" },
              }}
            >
              {data?.family_name?.trim()} Family
            </Typography>
          </Box>
          <Divider variant={"middle"} sx={{ mt: "1rem", mb: "3rem" }} />
        </>
      )}
      {data && hasMembers && data.is_current_user_admin && (
        <>
          <Box sx={{ mb: "1rem" }}>
            <Button
              variant={"outlined"}
              sx={{ mr: "1rem" }}
              onClick={() => handleCreateInvite()}
            >
              Invite Member
            </Button>
            <Button
              variant={"outlined"}
              onClick={() => navigate(`/family/${data.family_id}/managedraw`)}
            >
              Manage Name Draw
            </Button>
          </Box>
        </>
      )}

      {isLoading && <FamilyMemberListPlaceholder />}

      {!isLoading && !hasMembers && (
        <Alert severity="info">
          There are no members other than you. Invite your family!
          <Button
            variant={"outlined"}
            sx={{ ml: "1rem" }}
            onClick={() => handleCreateInvite()}
          >
            Invite Member
          </Button>
        </Alert>
      )}
      {!isLoading && members && (
        <>
          <Stack
            direction={"column"}
            flexWrap={"wrap"}
            spacing={2}
            useFlexGap
            sx={{ width: "100%", mb: "1rem" }}
          >
            {members?.map((member, i) => (
              <FamilyMember key={i} member={member} />
            ))}
          </Stack>
        </>
      )}
    </>
  );
}
